<template>
  <div class="container">
    <Hero />
    <div class="content">
      <router-view />
    </div>
  </div>
  <p style="position: fixed; bottom: 5px; left: 5px; z-index: 1000; font-size: 10px; opacity: 0.5;">{{ $config.BASE }}</p>
</template>

<script>
import Hero from "@/components/Hero"
import { tokenStore } from "@/store/TokenHolder"

export default {
  components: { Hero },
  async beforeMount() {
    // Проверка наличия доп. действия
    if (this.$route.query.action) {
      switch (this.$route.query.action) {
        case 'logout':
          this.token = null
          break;
      }
    }
  },
  mounted() {
    document.title = 'ЕИС'
  },
  computed: {
    token: {
      get() {
        return tokenStore().get()
      },
      set(value) {
        tokenStore().set(value)
      }
    }
  }
}
</script>

<style lang="scss">
.container {
  margin-top: 25vh !important;
}

html {
  position: relative;
  height: 100vh;
  width: 100vw;
}
html:after {
  content : "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("@/assets/bg.png");
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: 220px center;
  width: 100%;
  height: 100%;
  opacity : 0.1;
  z-index: -1;
}
</style>