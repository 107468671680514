import { defineStore } from 'pinia'
import Cookies from 'js-cookie'

export const tokenStore = defineStore('token', {
    state: () => {
        if (Cookies.get('token')) {
            return { token: Cookies.get('token') }
        } else {
            return { token: null }
        }
    },
    actions: {
        get() {
            return this.token
        },
        set(data) {
            if (data) {
                Cookies.set('token', data, { domain: window.config.host, expires: 30 })
                this.token = data
            } else {
                Cookies.remove('token', { domain: window.config.host })
                this.token = null
            }
        }
    },
})